import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import { Link } from "gatsby"

const Gdpr = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Terms of Service"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Service Provision Contract | Terms of Service"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <ol>
                  <li>
                    <p>GENERAL</p>
                  </li>
                </ol>
                <p>
                  This document contains the terms and conditions of use of the
                  services offered by EXIM HOST SRL through SiteBunker
                  (www.SiteBunker.net) or other owned sites.
                </p>
                <ol start="2">
                  <li>
                    <p>DEFINITION</p>
                  </li>
                </ol>
                <p>
                  The provider of hosting and domain registration services that
                  can be contracted and purchased through SiteBunker
                  (www.SiteBunker.ro) and the other owned sites is EXIM HOST
                  SRL: Fiscal Registration Code: RO40672655; Str. Frunzei no.
                  109, Galati, Romania.
                </p>
                <p>
                  User &ndash; defines any natural or legal person who uses
                  SiteBunker services.
                </p>
                <p>
                  Customer - defines any natural or legal person who initiates
                  an order.
                </p>
                <p>
                  Order &ndash; request addressed to SiteBunker by a natural or
                  legal person requesting web hosting services or domain
                  registration and which does not create contractual obligations
                  for the parties.
                </p>
                <ol start="3">
                  <li>
                    <p>INTRODUCTION</p>
                  </li>
                </ol>
                <p>
                  Art. 3.1.This document defines the framework in which
                  SiteBunker agrees to provide products and services to its
                  customers and users.
                </p>
                <p>
                  This document represents an agreement between SiteBunker and
                  its customers.
                </p>
                <p>
                  All customers and users of hosting services are subject to
                  these terms and conditions so that the use of SiteBunker
                  services implies acceptance and compliance with the Supply
                  Agreement and the Terms of Service offered by SiteBunker.
                </p>
                <p>
                  Art. 3.2 By completing the order form and checking the box: I
                  have read and accept the EXIM HOST SRL Service Supply
                  Agreement:
                </p>
                <ul>
                  <li>
                    <p>
                      represents a guarantee that you are a natural person, that
                      you are 18 years old.If you represent a legal entity,
                      partnership or any other legal form of organization in
                      accordance with trade standards, your registration is a
                      guarantee.
                    </p>
                  </li>
                  <li>
                    <p>
                      you agree to provide correct, clear and real information
                      in the Order Form, including information related to the
                      bank account and any other type of information necessary
                      for the payment conditions and undertake to update this
                      information correctly and permanently.
                    </p>
                  </li>
                  <li>
                    <p>
                      represents and guarantees that you have the authority to
                      use the services according to this contract. If you do not
                      agree/do not express your agreement to the Terms of the
                      Contract or any other changes, the only option is to
                      disable this service.
                    </p>
                  </li>
                </ul>
                <ol start="4">
                  <li>
                    <p>&nbsp;GENERAL CONDITIONS OF USE</p>
                  </li>
                </ol>
                <p>
                  Art. 4.1. The use of the site is possible only in case of
                  acceptance of the terms and conditions established by the
                  administrators of the site according to the legal provisions.
                  Continuing to use the site represents the express and
                  unequivocal acceptance of all the terms and conditions
                  presented in the Supply Agreement and on the SiteBunker.ro
                  website.
                </p>
                <p>
                  Art. 4.2. Use, including visiting and buying products and
                  services from the SiteBunker website, implies acceptance and
                  compliance with the terms and conditions both regarding the
                  use of the website and the purchase of products and services
                  sold by SiteBunker.
                </p>
                <p>
                  Art. 4.3. As a customer, you agree to pay for all the products
                  you purchase through the website, and make the payment for the
                  products you ordered.
                </p>
                <p>
                  Art. 4.4. The customer is responsible for the timely payment
                  of all commissions and for making available to SiteBunker the
                  sums of money, respectively the value of the products ordered
                  by the customer.
                </p>
                <p>
                  Art. 4.5. The total price will include the price of the
                  product plus any applicable VAT (in effect on the date of
                  order and payment).
                </p>
                <p>
                  Art. 4.6. The customer has the right to withdraw from the
                  transaction without any cost and without giving any
                  justification until the order processing date. The customer
                  does not have the right to withdraw from a transaction after
                  the moment when the processing of the order has started, when
                  the customer&apos;s transaction is final and confirmed by
                  SiteBunker.
                </p>
                <p>
                  Art. 4.7. Until an order is made, SiteBunker reserves the
                  right to change the prices for products offered through the
                  site, at any time, and SiteBunker does not offer price
                  guarantees or refunds in the event of a price reduction or a
                  promotional offer after the purchase.
                </p>
                <p>
                  Art. 4.8. By accepting the terms and conditions presented on
                  the SiteBunker.ro website or affiliated websites, at the time
                  of placing an order, the customer accepts and gives his
                  consent to the conclusion of a distance contract based on
                  which the distance sale will be made. This contract is one of
                  adhesion and is not subject to modification or negotiation
                  between the parties.
                </p>
                <p>Art. 4.9. Affiliate Program</p>
                <p>
                  Through the affiliate program, you earn 15% of the value of
                  Shared Hosting packages. Orders for domain names, VPS servers,
                  and reseller hosting packages are excluded.
                </p>
                <p>How Does It Work?</p>
                <p>
                  Each affiliate will receive a unique referral link that will
                  be used by their referred clients. When a visitor from your
                  website connects to our site through this unique referral
                  link, our system will automatically record the services
                  ordered by the client. After the services are paid for, you
                  will be able to see a 15% commission from the value of the
                  ordered services in your affiliate account.
                </p>
                <p>
                  The commission for the affiliate program will only be
                  considered if the affiliate brings unique clients per
                  commission.
                </p>
                <p>
                  Commissioning in the affiliate program is closely tied to
                  bringing in unique clients. This means that the affiliate
                  receives a commission only if they manage to bring in a new
                  client who has not used the respective services or products
                  before.
                </p>
                <p>
                  Common Aspects Related to Commissioning Based on Unique
                  Clients:
                </p>
                <p>
                  - Unique Client: Defined as a new client who has not been
                  previously registered in the company’s database and who has
                  not made any prior purchases.
                </p>
                <p>
                  - Tracking: Various tracking methods are used to ensure that
                  each referred client is unique. These include cookies, user
                  IDs, and other tracking technologies.
                </p>
                <p>
                  - Validation: After a new client is registered, the affiliate
                  program verifies and validates if the client is truly unique
                  before awarding the commission to the affiliate.
                </p>
                <p>
                  - Commission Payment: The affiliate receives the commission
                  only after the unique client is validated and, in some cases,
                  after the new client performs a certain action (e.g.,
                  purchase, subscription to a service).
                </p>
                <p>
                  Commission payments will be made after a 30-day period if a
                  minimum amount of 10 EUR is reached upon your request. In the
                  case of online payments, the commission payment period can be
                  extended up to 60 days to avoid fraud.
                </p>
                <p>
                 Art. 4.10. We do not accept domains registered with us and/or hosted 
                 on our servers that do not comply with GDPR standards and do not provide 
                 authenticity certificates for the products sold, or sell counterfeit products. 
                 Additionally, we do not accept domains and websites without visible and 
                 functional contact information. Such domains will be deactivated.
                </p>
                <p>
                  5. ACCEPTANCE OF THE TERMS OF SERVICE. PERIODICAL MODIFICATION
                  OF THESE
                </p>
                <p>
                  Art. 5.1. The SiteBunker website, its services and content are
                  offered to you subject to the customer&apos;s/user&apos;s
                  acceptance without any modification of the conditions in this
                  document. If, as a customer, you do not accept the terms and
                  conditions stated in this document, do not use the SiteBunker
                  website and the services offered through it.
                </p>
                <p>
                  Art. 5.2. By using the site, the customer indicates and
                  declares that he/she accepts without reservation or limitation
                  the &quot;Terms of Service&quot; of use. The administrators of
                  the site can revise this document at any time, by updating
                  this page, meaning that we recommend you visit this page
                  periodically to read the &quot;Terms of Service&quot;, because
                  this document legally binds the customer.
                </p>
                <p>
                  Art. 5.3. Each access to the site represents a new acceptance
                  of the terms and conditions in force at that time, as they are
                  displayed by the Site Administrator. An acceptance of the
                  terms of service in a previous form, through a previous access
                  to the site, does not affect new accesses subject to the new
                  terms and conditions.&nbsp;
                </p>
                <p>
                  Art. 5.4. Proof of the current content of the terms and
                  conditions will be made, wherever and whenever needed, through
                  a unilateral document emanating from the Site Administrator.
                </p>
                <p>6. OBLIGATION TO REGISTER ON THE SITE</p>
                <p>
                  To use the services offered by the SiteBunker website, the
                  user undertakes:
                </p>
                <ul>
                  <li>
                    <p>
                      to provide true, accurate and complete identification data
                      as required by the site&apos;s registration form;
                    </p>
                  </li>
                  <li>
                    <p>
                      to maintain and update, when the situation requires it,
                      the registration data to be true, accurate and complete.If
                      the customer provides information that is untrue,
                      inaccurate or incomplete, the SiteBunker site
                      administrators have the right to unilaterally suspend or
                      terminate the user&apos;s account and refuse all current
                      or future attempts to use the SiteBunker site.
                    </p>
                  </li>
                </ul>
                <p>7. ACCOUNT, PASSWORD AND DATA SECURITY</p>
                <p>
                  Art. 7.1. The user of the SiteBunker website is responsible
                  for maintaining the confidentiality of the information and of
                  the personal password, being responsible for the use of the
                  registration, regardless of whether the use is made with or
                  without his consent.
                </p>
                <p>
                  Art. 7.2. If the user notices an unauthorized use of his
                  account, he is obliged to notify the administrator of the
                  SiteBunker site in connection with any unauthorized use of the
                  password and personal registration.
                </p>
                <p>
                  Art. 7.3. The user also agrees to use the exit button from the
                  personal account when he wants to leave the site and the
                  account.
                </p>
                <p>
                  Art. 7.4. The SiteBunker company and the administrators of the
                  SiteBunker website will not be liable for any moral or
                  material damage caused by the user&apos;s non-compliance with
                  these provisions.
                </p>
                <p>
                  Art. 7.5. The administrators of the SiteBunker website will be
                  able to block the password, the use of the services or the use
                  of any of its other services by the users, or remove and
                  delete any material from the services, for any reason, without
                  being limited to the reasons provided in art. 8.10, 8.11 of
                  this contract.
                </p>
                <p>
                  Art. 7.6. Any termination of the user&apos;s access to the
                  services offered on the SiteBunker website, can be carried out
                  without prior warning, the SiteBunker website administrators
                  having the unlimited right to immediately disable or delete
                  the user&apos;s account from the website and all information
                  related to it and/or prohibit any further access to the
                  services offered by SiteBunker.
                </p>
                <p>
                  Art. 7.7. SiteBunker will not be liable to the user or any
                  third party in any way, for the prohibition and/or deletion of
                  the account and access to the services made available through
                  the SiteBunker website.
                </p>
                <p>8. COPYRIGHTS AND TRADEMARK RIGHTS</p>
                <p>
                  Art. 8.1. The entire content of the SiteBunker website belongs
                  to SiteBunker and is protected according to the copyright law
                  and the laws regarding the right to intellectual and
                  industrial property. The use without the written consent of
                  Sitebunker of any elements belonging to the SiteBunker website
                  is punishable according to the laws in force.
                </p>
                <p>
                  Art. 8.2. By accessing the site, the customer is not granted
                  any rights over the material on the site, other than those
                  implied by the normal use of the site.
                </p>
                <p>
                  Art. 8.3. SiteBunker gives the user the right to download the
                  information from the site only for their own, non-commercial
                  use, provided that they do not modify the symbols and text
                  regarding copyright and author&apos;s rights.
                </p>
                <p>
                  Art. 8.4. SiteBunker does NOT grant the user the right to
                  partially or fully modify the website, to reproduce partially
                  or fully the website, to copy, sell or exploit the website in
                  any other way without SiteBunker&apos;s consent.
                </p>
                <p>
                  Art. 8.5. The user will not modify, copy, distribute,
                  transmit, display, publish, reproduce, grant licenses, create
                  derivative products, transfer or sell any kind of information
                  or services obtained from or through this website.
                </p>
                <p>
                  Art. 8.6. The name SiteBunker, the SiteBunker logo, are
                  trademarks registered by EXIM HOST SRL. Other brands are also
                  mentioned on the site. They are used by SiteBunker, either
                  with the owner&apos;s authorization, or as a simple indication
                  of some products or services marketed and offered by
                  SiteBunker.
                </p>
                <p>
                  Art. 8.7. The reproduction, imitation, use or application of
                  these marks without obtaining prior authorization from
                  SiteBunker or from the respective owners is a crime and is
                  punishable according to the laws in force.
                </p>
                <p>9. ORDER, PAYMENTS AND BILLING</p>
                <p>
                  Art. 9.1. Any order will be made online on the SiteBunker
                  website https://client.SiteBunker.net/. Customers are obliged
                  to enter complete, correct and factual data in the form.
                  Domain registration is done in accordance with the laws in
                  force. SiteBunker cannot be held responsible in any way for
                  inconvenience caused by the provision by customers of
                  incorrect or incomplete information.
                </p>
                <p>
                  The order placed will be confirmed by an email whose role is
                  to notify the customer that the new order has been registered
                  in our system.
                </p>
                <p>
                  Making and registering the order in our system does not
                  involve any contractual obligations for any of the parties.
                  The customer can cancel the order by not paying the issued
                  proforma invoice and SiteBunker can cancel the order
                  acceptance and processing by canceling it and returning the
                  amount paid (if applicable).
                </p>
                <p>
                  The customer agrees that this confirmation of receipt of the
                  order does not represent a confirmation of the validity of the
                  order or the processing of the ordered services, and does not
                  generate contractual obligations on the part of SiteBunker.
                </p>
                <p>
                  The ordered services will be confirmed and processed only
                  after the payment has been made and the verification has been
                  carried out by the SiteBunker staff.
                </p>
                <p>
                  Art. 9.2. The customer has the obligation to inform SiteBunker
                  when placing the order or later if necessary, if it is
                  registered for VAT purposes.
                </p>
                <p>
                  Art. 9.3. Information regarding Personal Data Protection:{" "}
                  <a href="https://sitebunker.net/privacy-policy/">
                    https://sitebunker.net/privacy-policy/
                  </a>
                </p>
                <p>
                  The customer has the obligation to read the Information Note
                  regarding the Protection of Personal Data before continuing
                  the process of ordering and sending data to EXIM HOST SRL.
                </p>
                <p>
                  Art. 9.4. After completing the order, a proforma invoice is
                  automatically sent to the email address entered in the form.
                  The proforma invoice contains all the information necessary to
                  make the payment and is available online in the
                  customer&apos;s account. The proforma invoice has no
                  accounting value.
                </p>
                <p>
                  The order is considered accepted by SiteBunker after its
                  processing in the system.
                </p>
                <p>
                  Art. 9.5. The customer agrees to pay the value of our services
                  in advance for the period of time in which they are provided.
                </p>
                <p>
                  Art. 9.6. All proforma invoices will be sent by email and a
                  printable version will be made available to the customer in
                  the control panel. Customers must pay the counter value of the
                  invoice in advance at the beginning of each payment period,
                  within 5 working days from the date of issuance of the
                  proforma invoice.
                </p>
                <p>
                  Art. 9.7. After making the payment and collecting the money,
                  the tax invoice is issued. Tax invoices are sent in electronic
                  &quot;PDF&quot; format by email.
                </p>
                <p>
                  Art. 9.8. Tax invoices issued after June 1, 2008 are available
                  in PDF format and in the customer account in the &quot;Tax
                  Invoices&quot; section.
                </p>
                <p>
                  Art. 9.9. SiteBunker recommends confirming payments by sending
                  a copy (photo, scan) certifying this (with the bank&apos;s
                  stamp) by email (according to our contact page). Customers
                  benefit from SiteBunker services in 1-24 hours after payment
                  confirmation.
                </p>
                <p>
                  Art. 9.10. Suspension - We reserve the right to suspend any
                  account after 15 days from the due date of payment of the
                  proforma invoice if payment has not been made. Any extension
                  of the payment term is at our discretion.
                </p>
                <p>
                  Art. 9.11. Account Deletion - We reserve the right to
                  permanently delete all web hosting account data after 30 days
                  from the due date of the proforma invoice if payment has not
                  been made. Any extension of this term is at our discretion.
                </p>
                <p>
                  If the customer requests the resumption of a hosting service
                  after it has been deleted from the server, we will check if
                  there is a backup copy on the existing back-up solutions. If
                  this copy exists, a new hosting invoice will be issued for the
                  same package as the previous one, and the content will be
                  restored after payment.
                </p>
                <p>
                  If you do not want to continue the hosting service and you
                  request the provision of a back-up, it will be provided only
                  after paying a fee of 20 USD to cover the storage and the cost
                  of providing the back-up on the storage solutions existing as
                  well as the delivery in electronic format (download link) of
                  the existing copy.
                </p>
                <p>
                  We do not offer any kind of guarantee regarding the existence
                  of a backup copy on the servers or its integrity.
                </p>
                <p>
                  Art. 9.12. Cash or OP Payment (Bank Deposit or Bank Transfer)
                  &ndash; All invoices and direct payments (cash) or by bank
                  transfer (OP, Cash Deposit) to SiteBunker are processed in USD
                </p>
                <p>
                  Art. 9.13. Online Payment (Credit / Debit Card or PayPal)
                  &ndash; Online payments are made securely through Stripe
                  www.stripe.com/ and are subject to certain terms and
                  conditions. Please read these terms and conditions here..
                </p>
                <p>
                  Art. 9.14. Payment for Dedicated Server services is made in
                  advance. If the payment of a Dedicated Server is not made
                  within 15 days from the due date of the invoice, the server
                  will be closed, without deleting the customer&apos;s data from
                  it. Data will be deleted 30 days after the due date of the
                  invoice.
                </p>
                <p>10. DOMAIN REGISTRATION</p>
                <p>
                  Art. 10.1. Domain registration or reservation (in the case of
                  RO domains) is done after receipt of the counter value of the
                  proforma invoice issued for the registration services
                  regardless of the domain type .ro, .eu, .com, etc.
                </p>
                <p>
                  Important: Domains once registered cannot be deleted or
                  modified regardless of the extension!
                </p>
                <p>
                  Art. 10.2. SiteBunker is not responsible for the situation in
                  which between the time of placing the order and payment and
                  registering the domain, it is registered by another person or
                  another hosting company.
                </p>
                <p>
                  In this case, the customer can request either the refund of
                  the amount paid for the domain, or the registration of another
                  domain with the same extension.
                </p>
                <p>
                  We are not responsible for non-payment on time of domains that
                  require renewal, which may lead to the payment of an
                  activation fee or the loss of the domain.
                </p>
                <p>
                  The activation fees depend on each type of domain and are
                  displayed on SiteBunker.net/.
                </p>
                <p>
                  Pointing domains to hosting is done through nameservers, which
                  are set by the customer or by us at the customer&apos;s
                  request. For domains, the possibility of advanced
                  administration of the DNS zone is not offered except in the
                  case of holding a hosting package, in which case the advanced
                  administration of the DNS zone is done through Cpanel.
                </p>
                <p>
                  Art. 10.3.In the case of Romanian or international domains,
                  the data is sent to the issuing authorities of these domains
                  (ROTLD, ICANN, Eurid, or international authorities that issue
                  various domains). SiteBunker acts as a partner of these
                  institutions, taking the data provided by the customer for
                  registration and sending them to the issuers&apos; system for
                  registration.
                </p>
                <p>
                  The rules for displaying personal data in the case of these
                  domains are the responsibility of these authorities.
                </p>
                <p>
                  In the case of Rotld:{" "}
                  <a href="http://www.rotld.ro/">http://www.rotld.ro/</a>
                </p>
                <p>
                  In the case of Eurid:{" "}
                  <a href="http://www.eurid.eu/ro/">http://www.eurid.eu/ro/</a>
                </p>
                <p>
                  In the case of international domains issued by ICANN,
                  Processed through Demand Media INC, USA:{" "}
                  <a href="http://www.enom.com/terms/privacy.asp">
                    http://www.enom.com/terms/privacy.asp
                  </a>
                </p>
                <p>
                  For domains processed through ENOM (com, net, info, biz, org)
                  there is the possibility of hiding the owner&apos;s data
                  through the ID Protect option, available for a fee.
                </p>
                <p>
                  If the customer does not agree with the rules for registering
                  these international domains, the registration cannot be done.
                </p>
                <p>
                  Art. 10.4. Domain registration is done in accordance with the
                  general rules imposed by ICANN (Internet Corporation for
                  Assigned Names and Numbers). Depending on the type of domain,
                  the TLD (Top Level Domain) administrator imposes clear rules
                  for registering and using Internet domains.
                </p>
                <p>ICANN Consensus Policies</p>
                <p>
                  SiteBunker registers these domains through its partner ENOM,
                  customers who register international domains or choose to
                  renew them must comply with ENOM&apos;s registration rules:
                  http://www.enom.com/terms/agreement.aspx
                </p>
                <p>Important: Order and registration of PREMIUM domains</p>
                <p>
                  If the domain ordered by you is a PREMIUM one, we will not be
                  able to register the domain for you. The determination of the
                  PREMIUM status of a domain is determined when the domain
                  registration process is started by the SiteBunker staff, or
                  automatically by our systems.
                </p>
                <p>
                  If the ordered domain is a PREMIUM one, we will contact you to
                  establish either the return of the amount paid by you for the
                  order or to inform you of the price at which the respective
                  PREMIUM domain can be registered, or to register another
                  domain with the same extension.
                </p>
                <p>
                  Note: PREMIUM domains are domains consisting of a few letters
                  or with very well-known or common names, but not limited to
                  these rules. The PREMIUM status of a domain is determined and
                  provided by the ENOM system through spot checks and may not be
                  available when the order is placed, but only at the stage of
                  its verification by SiteBunker.
                </p>
                <p>
                  Art. 10.5. Registration of .ro Domains &ndash; SiteBunker is a
                  RoTLD (Romanian Top Level Domain) partner and the registration
                  of domains in the .RO Zone requires reading and accepting all
                  the rules imposed and regulated by RoTLD.
                </p>
                <p>
                  In accordance with the Partnership Agreement concluded with
                  RoTLD, SiteBunker displays the information regarding the
                  registration of .ro domains, the Registration Rules, the
                  Registration Agreement and the rest of the information
                  regarding the registration and use of a .ro domain.
                </p>
                <p>
                  The customer agrees to consult the information provided. If
                  you do not agree with the existing rules and conditions for
                  the registration and use of .ro domains, do not place the
                  order. Completing the order means accepting all these terms
                  and conditions.
                </p>
                <p>
                  The domain registration data are completed by the customer
                  upon order and are automatically transmitted via API to the
                  authority issuing the .ro domains, RoTLD, without interfering
                  with them in the reservation and registration process.
                </p>
                <p>Registration Rules</p>
                <p>Registration Agreement</p>
                <p>
                  Based on the ICI RoTLD decision, the annual maintenance fee
                  was introduced starting on March 1, 2018. The right to use a
                  domain is acquired after paying the registration fee. The
                  extension of the right to use a domain is done by paying the
                  maintenance fee before the expiration of the valid period of
                  the right of use (expiry date). Failure to pay the maintenance
                  fee by the expiration date leads to the suspension and
                  deletion of the respective domain.
                </p>
                <p>
                  SiteBunker, as a partner (reseller) of RoTLD, offers domain
                  registration services based on a partnership contract with
                  RoTLD and is subject to RoTLD rules and decisions. SiteBunker
                  does not guarantee and cannot guarantee in any way that any of
                  the existing conditions for domain registration (registration
                  term, administration method, various fees) will not be
                  modified by RoTLD in any way, including for already registered
                  domains.
                </p>
                <p>
                  In case of changes that affect the holders of .ro domains,
                  SiteBunker will communicate through the website and other
                  channels at its disposal the information made available by
                  RoTLD, in the shortest possible time after they become public
                  and SiteBunker has taken knowledge of this information.
                </p>
                <p>
                  Art. 10.6. .EU domains &ndash; SiteBunker provides
                  registration, renewal and transfer services of .EU domains in
                  accordance with the rules imposed by EURid (European Registry
                  of Internet Domain Names) and the registration of .EU domains
                  implies reading and accepting all the rules imposed and
                  regulated by EURid.
                </p>
                <p>
                  Art. 10.7. International Domains - SiteBunker provides
                  registration, renewal and transfer services of International
                  domains, through the partnership with eNom (eNom, Inc.
                  Chicago, IL), in accordance with the specific rules of each
                  type of domain, rules imposed by TLD (Top Level Domain)
                  administrators .
                </p>
                <p>
                  . COM &ndash; Domains with commercial purpose. ICANN &ndash;
                  COM rules apply.
                </p>
                <p>
                  . NET &ndash; Domains for networks, general or commercial.
                  ICANN &ndash; NET rules apply.
                </p>
                <p>
                  . ORG &ndash; Domains for organizations or commercial. ICANN
                  &ndash; ORG rules apply.
                </p>
                <p>
                  . BIZ &ndash; Domains for companies or commercial use. ICANN
                  &ndash; BIZ rules apply.
                </p>
                <p>
                  . INFO &ndash; General purpose domains. ICANN - INFO rules
                  apply.
                </p>
                <p>
                  . US &ndash; Domains for the United States of America only.
                  usTLD rules apply.
                </p>
                <p>
                  . UK &ndash; Domains for the United Kingdom of Great Britain
                  only. Nominet rules apply.
                </p>
                <p>
                  . IN &ndash; Domains India. It can be registered regardless of
                  the country.
                </p>
                <p>
                  . NAME &ndash; Domains for proper names. ICANN &ndash; NAME
                  rules apply.
                </p>
                <p>. MOBI &ndash; Mobile device domains. MOBI details.</p>
                <p>
                  Art. 10.8. If the domain ordered by the customer cannot be
                  registered for any reason (incomplete or wrong identification
                  data, unavailability, rules imposed by TopTLD and which are
                  not respected by the future owner of the domain, premium
                  domains, requirements regarding the country of residence,
                  legal status, field of activity, other situations) the order
                  will not be accepted and will be canceled and the amounts paid
                  by the customer for the registration of the domain will be
                  returned.
                </p>
                <p>11. PROTECTION OF PERSONAL DATA</p>
                <p>
                  Art. 11.1. According to the requirements of Law no. 677/2001
                  for the protection of individuals regarding the processing of
                  personal data and the free circulation of these data, amended
                  and supplemented and of Law no. 506/2004 regarding the
                  processing of personal data and the protection of private life
                  in the electronic communications sector, EXIM HOST SRL has the
                  obligation to manage in safe conditions and only for the
                  specified purposes, the personal data that you provide us
                  about you, a family member you or another person.
                </p>
                <p>
                  The purpose of data collection is: providing services and
                  products according to the current offer, as well as
                  registering web domains on behalf of customers and according
                  to their orders.
                </p>
                <p>
                  You are compelled to provide the data, these being necessary
                  to be able to identify the recipient of the services or
                  products provided, or to register the domains in your name (as
                  a customer). Your refusal determines the impossibility of
                  providing the service or product, or the registration of the
                  ordered domains.
                </p>
                <p>
                  The recorded information is intended for use by the operator
                  and, as the case may be, is communicated only to the following
                  recipients: the national/European/world
                  registration/administration authorities of web domains or
                  their authorized partners (ROTLD, EUrid, Enom).
                </p>
                <p>
                  In the case of international domains, the data is sent to the
                  issuing authorities of these domains (ICANN, Eurid, or
                  international authorities that issue various domains). The
                  rules for displaying personal data in the case of these
                  domains belong to these authorities:
                </p>
                <p>Domains.EU</p>
                <p>
                  In the case of Eurid (WHOIS Policy section):
                  https://eurid.eu/ro/
                </p>
                <p>
                  The Eurid rules for registering .EU domains require the
                  display of the domain owner&apos;s contact data in the Whois
                  section of eurid.eu, without the possibility of hiding the
                  data.
                </p>
                <p>International domains .COM, .NET, .ORG, .INFO, etc</p>
                <p>
                  In the case of international domains issued by ICANN,
                  Processed through Demand Media INC, USA:{" "}
                  <a href="http://www.enom.com/terms/privacy.asp">
                    http://www.enom.com/terms/privacy.asp
                  </a>
                </p>
                <p>
                  The rules for ICANN &ndash; ENOM domains assume the display of
                  the domain owner&apos;s data in Whois searches.
                </p>
                <p>
                  For domains processed through ENOM, there is the possibility
                  of hiding the owner&apos;s data through the ID Protect option,
                  available for a fee.
                </p>
                <p>
                  Important: If the customer does not agree with the rules for
                  registering these international domains, the registration
                  cannot be done.
                </p>
                <p>
                  According to Law no. 677/2001, you benefit from the right of
                  access, intervention on the data, the right not to be subject
                  to an individual decision and the right to go to court.
                </p>
                <p>
                  At the same time, you have the right to object to the
                  processing of personal data concerning you and to request the
                  deletion of the data*. To exercise these rights, you can send
                  a written, dated and signed request to{" "}
                  <a href="mailto:contact@SiteBunker.net">
                    contact@SiteBunker.net
                  </a>
                  .&nbsp;
                </p>
                <p>
                  You are also recognized as having the right to address
                  yourself to justice.
                </p>
                <p>
                  If some of the data about you are incorrect, please inform us
                  as soon as possible.
                </p>
                <p>
                  Note: *any person has the right to oppose, for legitimate
                  reasons, the processing of data concerning him. This right of
                  opposition can be excluded for certain processing provided by
                  law (eg: processing carried out by the financial and fiscal
                  services, police, justice, social security). Therefore, this
                  mention cannot appear if the processing is mandatory; any
                  person also has the right to oppose, free of charge and
                  without any justification, the processing of his personal data
                  for direct marketing purposes.
                </p>
                <p>
                  Art. 11.2. By accepting this contract, we inform you that the
                  data provided when registering the domains and the data
                  provided for invoicing the services provided will be
                  transmitted to the authorities that have the legal right to
                  request them, in the cases in which they will be requested by
                  them, based on requests received from the authorities
                  respectively.
                </p>
                <p>12. GUARANTEES AND REFUNDS</p>
                <p>
                  Art. 12.1. SiteBunker offers the possibility of return within
                  14 days!
                </p>
                <p>
                  Customers have the right to a refund of the amount paid for
                  web hosting services within 14 calendar days from the payment
                  of the hosting package, without specifying the reasons.
                  Customers who want a refund for hosting services must request
                  this through an unequivocal statement via an email to{" "}
                  <a href="mailto:contact@SiteBunker.net">
                    contact@SiteBunker.net
                  </a>{" "}
                  or through the integrated support system. The request must
                  contain the customer&apos;s identification data, the
                  confirmation that all the data on the server has been saved,
                  optionally the reasons for requesting the return of the money,
                  as well as the information necessary for the return operation.
                </p>
                <p>
                  If the customer also purchased a domain together with the
                  hosting package and it benefited from a discount offer, the
                  entire value of the domain will be deducted from the total
                  amount paid (at the registration price without discount). The
                  domain will remain the customer&apos;s property until
                  expiration, depending on the registration conditions specific
                  to each TLD.
                </p>
                <p>
                  Bank Commissions and those for Card Payment Processing (if
                  applicable) are deducted from the returned amount. Payment of
                  the returned amount minus commissions will be made within a
                  maximum of 30 days from the date of receipt of the refund
                  request.
                </p>
                <p>
                  The customer must save all data from the web hosting account
                  before making the refund request. From cPanel you can generate
                  and download an archive that includes all the account data
                  automatically.
                </p>
                <p>
                  Payment for domain registration cannot be returned from the
                  moment the domain was registered, it becomes the
                  customer&apos;s property until the registration term expires.
                </p>
                <p>
                  The following are not subject to the return guarantee: domain
                  registration, security certificates, licenses or dedicated
                  servers, any other services or products that are
                  individualized at the customer&apos;s request (domain
                  registration, IP-based activation outside shared hosting
                  services, allocation on 1 certain field of a license).
                </p>
                <p>
                  Art. 12.2. The return cannot be offered in certain cases and
                  for certain services. Payment for domain registration,
                  licenses, discounts or other freebies is not refundable. In
                  the event of a refund request for web hosting packages, the
                  price of the domain, licenses, discounts or freebies will be
                  deducted from the total amount. This rule also applies to
                  customers who received a free domain, a license, discount or
                  free of charge following a promotional offer.
                </p>
                <p>
                  Payment for domain registration cannot be returned from the
                  moment the domain was registered, the domain becomes the
                  customer&apos;s property until the registration term expires.
                </p>
                <p>
                  Since special services such as Dedicated Servers have a high
                  cost of commissioning, the payment cannot be refunded, the
                  servers will remain in operation until the term expires
                </p>
                <p>Art. 12.3. SiteBunker service performance guarantee</p>
                <p>
                  In case of non-functioning (downtime) of the servers /
                  services offered by EXIM HOST SRL for more than 1% of a month,
                  the customer has the right to an additional month of free web
                  hosting. The amount of compensation cannot exceed the monthly
                  or total amount paid by the customer &nbsp;for the hosting
                  service. Compensation is offered only at the customer&apos;s
                  request in the form of free hosting services granted in the
                  form of months.
                </p>
                <p>
                  The non-functioning of the hosting packages must be due to
                  hardware or software reasons related to the EXIM HOST SRL
                  equipment and infrastructure and does not apply to external
                  factors such as: connections to internet providers,
                  electricity, gas, utilities or special emergency cases in the
                  laws in force.
                </p>
                <p>
                  Art. 12.4. The refund guarantee does not apply if the customer
                  does not comply with the terms of the EXIM HOST SRL service
                  provision contract.
                </p>
                <p>13. USE OF THE SERVICES</p>
                <p>
                  Art. 13.1. The services offered by SiteBunker will be used
                  only for legal purposes. Any activities that can be considered
                  illegal are strictly prohibited.
                </p>
                <p>
                  Art. 13.2. SiteBunker reserves the right to delete or block
                  access to content on the site or server without prior notice.
                  The right to use SiteBunker services will be restored after
                  the account holder becomes legal.
                </p>
                <p>
                  Art. 13.3. If a resold account violates the provisions of this
                  document, we reserve the right to suspend this account without
                  informing the reseller in advance. The end user will not be
                  contacted by us, and the reseller (SiteBunker customer) will
                  be informed of our facts and reasons.
                </p>
                <p>
                  Art. 13.4. The space offered can only be used for storing
                  files used on the site (html files, documents, scripts,
                  images, multimedia files, emails) or related to the
                  site&apos;s or company&apos;s activity. It is not allowed to
                  store files for filesharing, torrents, mass downloads, etc.
                </p>
                <p>
                  Art. 13.5. Our servers will not be used in any form and under
                  any circumstances for the transmission, storage or publication
                  of illegal materials (including the legislation of Romania,
                  the European Union and the USA). Illegal materials include,
                  but are not limited to:
                </p>
                <ul>
                  <li>
                    <p>
                      Violation of a copyright or any other right of any third
                      party;
                    </p>
                  </li>
                  <li>
                    <p>
                      Materials or Links to movies, TV channels, mp3 or other
                      media formats;
                    </p>
                  </li>
                  <li>
                    <p>
                      Promotion of illegal activities (hacking, cracking, warez,
                      etc.);
                    </p>
                  </li>
                  <li>
                    <p>
                      Materials protected by trade secrets or any other status;
                    </p>
                  </li>
                  <li>
                    <p>Threats, abuse, harassment, slanderous statements;</p>
                  </li>
                  <li>
                    <p>
                      Materials intended to encourage feelings of hatred or
                      discrimination;
                    </p>
                  </li>
                  <li>
                    <p>
                      Adult content, nudity, pornography, any image or text with
                      sexual or obscene content;
                    </p>
                  </li>
                  <li>
                    <p>
                      Information or software about or containing any type of
                      viruses or Trojans;
                    </p>
                  </li>
                  <li>
                    <p>
                      Collection of personal information for use for illegal
                      purposes;
                    </p>
                  </li>
                  <li>
                    <p>
                      Personal data without the written consent of the persons
                      concerned;
                    </p>
                  </li>
                  <li>
                    <p>Any content deemed by us to be harmful or illegal.</p>
                  </li>
                </ul>
                <p>
                  Art. 13.6. Pornography or any other materials with sexual,
                  erotic, nudity or obscene content, links, redirects or other
                  media related to pornography are prohibited on our servers.
                  SiteBunker reserves the right to decide on the legality of
                  these sites and to block public access or suspend services.
                </p>
                <p>
                  Art. 13.7. The following scripts are not allowed to be used on
                  our servers:
                </p>
                <ul>
                  <li>
                    <p>Scripts for Proxy or IRC;</p>
                  </li>
                  <li>
                    <p>Scripts for Torrent Tracker or Torrent files;</p>
                  </li>
                  <li>
                    <p>Scripts for Charts or Statistics;</p>
                  </li>
                  <li>
                    <p>Scripts for File Sharing;</p>
                  </li>
                  <li>
                    <p>
                      Scripts for Matrimonial or Intermediate Swing, Sexual,
                      Erotic, Adult;
                    </p>
                  </li>
                  <li>
                    <p>Scripts for Social Networks, Online Communities;</p>
                  </li>
                  <li>
                    <p>Scripts for Url Redirection (url shortener);</p>
                  </li>
                  <li>
                    <p>PhpShell and similar scripts for executing commands;</p>
                  </li>
                  <li>
                    <p>FormMail type scripts;</p>
                  </li>
                  <li>
                    <p>
                      Chat Scripts, Live Support or any other live
                      chat/discussion system;
                    </p>
                  </li>
                  <li>
                    <p>
                      Distribution scripts (hosts or links) media, video, music,
                      live stream;
                    </p>
                  </li>
                  <li>
                    <p>
                      Scripts (commercial or non-commercial) that contain known
                      security holes;
                    </p>
                  </li>
                  <li>
                    <p>
                      Commercial scripts (paid or free) not updated to the
                      latest available version;
                    </p>
                  </li>
                  <li>
                    <p>Scripts for streaming.</p>
                  </li>
                </ul>
                <p>
                  Art. 13.8. The provider reserves the right to suspend within
                  12 hours of notifying the customer any website that promotes
                  fake news. Any page or material that, following an internal
                  report or analysis, is identified as promoting fake news, we
                  do not reserve the right to block public access or suspend
                  services.
                </p>
                <p>14. SENDING MESSAGE POLICY</p>
                <p>
                  Art. 14.1. Sending e-mail messages for commercial, advertising
                  or mass purposes to e-mail addresses once or regularly must
                  comply with the conditions imposed by us. Such campaigns can
                  only be operated with e-mail lists in which people sign up
                  personally by express agreement, (according to law 365/2002
                  Art 6, paragraph 1 and 2) to receive such messages and in all
                  messages the procedure of withdrawal from the lists. Any
                  complaint received in this regard will be investigated and the
                  necessary measures will be taken.
                </p>
                <p>
                  Art. 14.2. Conditions that must be respected when sending
                  commercial, advertising or mass messages:
                </p>
                <ul>
                  <li>
                    <p>
                      Email marketing campaigns or mass emails are only allowed
                      if the customer has a Dedicated IP address.
                    </p>
                  </li>
                  <li>
                    <p>
                      Any email list larger than 2000 addresses requires a
                      dedicated specialized solution.
                    </p>
                  </li>
                  <li>
                    <p>
                      The sending of messages must be divided so that no more
                      than 100 messages are sent per hour. (sending higher
                      values leads to blocking the sending of all messages by
                      servers such as yahoo, google, hotmail).
                    </p>
                  </li>
                  <li>
                    <p>
                      Sending mass email messages must be done through a script
                      that uses the SMTP connection.
                    </p>
                  </li>
                  <li>
                    <p>
                      The use of sendmail from CGI or PHP &quot;mail()&quot; is
                      prohibited for sending mass email messages.
                    </p>
                  </li>
                  <li>
                    <p>
                      The addresses passed to Return-path:, From:, Reply-To:
                      must work and have sufficient space allocated.
                    </p>
                  </li>
                  <li>
                    <p>
                      Send messages only to people who have given their express
                      consent to receive messages by email.
                    </p>
                  </li>
                  <li>
                    <p>
                      Archiving of the data entered by the recipient when
                      registering in the email lists, including the date and
                      time of registration, IP address and data about the visit.
                    </p>
                  </li>
                  <li>
                    <p>
                      Include in each email sent the complete and true
                      identification data of the sender. Include in each email
                      the possibility to give up receiving email messages, which
                      must be respected.
                    </p>
                  </li>
                  <li>
                    <p>
                      Email address lists must be kept up-to-date, assuring that
                      you have the resources to do so.
                    </p>
                  </li>
                </ul>
                <p>
                  Art. 14.3. Any notification received in this regard and
                  confirmed will lead to the immediate suspension of the
                  respective account without prior notice to the hosting account
                  holder or the reseller.
                </p>
                <p>15. ANTI SPAM POLICY</p>
                <p>
                  Art. 15.1. Sending commercial, advertising or unsolicited
                  messages generically called SPAM for the promotion of any site
                  using our servers or through other servers for the promotion
                  of any site hosted on our servers will result in the
                  suspension or cancellation of the customer&apos;s hosting
                  account, without the right to a refund. Email lists can be
                  operated under the conditions that people sign up to receive
                  messages through express personal acceptance and the
                  withdrawal procedure from the lists is published in all
                  messages. Any complaint received in this regard will be
                  investigated and the necessary measures will be taken.
                </p>
                <p>Art. 15.2. A message can be considered SPAM even if:</p>
                <ul>
                  <li>
                    <p>
                      It has the phrase &quot;This email cannot be considered
                      SPAM...&quot; or other similar phrases.
                    </p>
                  </li>
                  <li>
                    <p>
                      If the identification data of the sender is incomplete or
                      incorrect.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you cannot prove that a certain person has agreed to
                      receive email messages from you.
                    </p>
                  </li>
                  <li>
                    <p>
                      If the withdrawal procedure from the email database does
                      not work.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you use email lists bought (even with an invoice!) or
                      received in exchange.
                    </p>
                  </li>
                  <li>
                    <p>The email message is being sent from another server.</p>
                  </li>
                </ul>
                <p>
                  Art. 15.3. Any notification received in this regard and
                  confirmed will lead to the immediate suspension of the
                  respective account without prior notice to the hosting account
                  holder or the reseller.
                </p>
                <p>
                  Art. 15.4. At the first violation of the rules for sending
                  unsolicited commercial messages generically called SPAM
                  stipulated in the contract, the hosting services will be
                  suspended or the sending of messages will be limited.The
                  unlocking or lifting of the limits is done only after sending
                  to EXIM HOST SRL by fax or e-mail a declaration on your own
                  responsibility signed, dated and stamped (in the case of
                  companies) with the text &quot;I have read, understand and
                  agree with the sending policies of the e-mail messages
                  stipulated in the Service Supply Agreement, EXIM HOST
                  SRL.&quot; accompanied by a copy of the identity document or
                  of the registration certificate in the case of
                  companies.&nbsp;
                </p>
                <p>
                  Art. 15.5. The use of online forms for sending e-mails is done
                  only through secure scripts to which access is restricted by
                  password or anti-robot systems. Failure to comply with these
                  provisions leads to the deactivation of the scripts or the
                  suspension of the account.
                </p>
                <p>16. USE OF RESOURCES</p>
                <p>
                  Art. 16.1. All accounts are monitored to track server resource
                  consumption. If an account uses a very large amount of
                  resources, we will contact the respective customer to discuss
                  this. If an account uses too many resources (memory,
                  processor), the account can be suspended or the script
                  disabled without prior information to the customer, in order
                  to maintain the proper functioning of the server. Within the
                  &quot;Shared Hosting&quot; web hosting and reseller packages,
                  large resource consuming users will not be accepted under any
                  circumstances. SiteBunker oversees compliance with the above
                  by monitoring reports on resources used.
                </p>
                <p>
                  Art. 16.2. Customers can request the improvement of the
                  package or the temporary increase of the allocated resources.
                  It is the customer&apos;s responsibility to contact us in time
                  to request an increase in resources to avoid account
                  suspension. In some cases, accounts are automatically
                  suspended when the limit of allocated resources is reached,
                  for reactivation, manual intervention on our part is required.
                </p>
                <p>17. RESTRICTIONS, LIMITATIONS AND UPDATES</p>
                <p>
                  Art. 17.1. SiteBunker periodically updates without prior
                  notice the software / system programs running on the servers
                  (examples: Operating System, cPanel (including additional
                  software), PHP, MySQL, FTP, Apache (Web Server), Exim (Mail
                  Server) etc...) to stable versions made available by the
                  producers of the respective software.
                </p>
                <p>
                  Under certain conditions, it is possible that functionalities
                  and/or software and programs offered will be permanently
                  removed or replaced with others to cover security holes,
                  design errors and ensure the safe operation of the servers.
                </p>
                <p>
                  Art. 17.2. We reserve the right to impose hardware and
                  software restrictions on the use of SiteBunker services
                  without prior notice, in case of known security problems that
                  may lead to malfunctioning of the servers or exploitation of
                  the programs installed on the server, even if this leads to
                  the blocking of permanent access have temporary web hosting
                  services.
                </p>
                <p>
                  Art. 17.3. If SiteBunker finds out, following the monitoring
                  of the servers or following a notification, an account in
                  which illegal activities are carried out or files that can be
                  used for illegal activities, the services will be limited,
                  blocked or suspended. SiteBunker will notify the customer and
                  send examples of files or software from within the hosting
                  account. It is likely that the illegal activities are carried
                  out by attackers / hackers who speculate on the software used
                  on the site or passwords saved on the computer.
                </p>
                <p>
                  Art. 17.4. Automatic Anti Malware and Antivirus protection
                </p>
                <p>
                  SiteBunker uses special software to detect attacks and malware
                  infections. These specialized softwares perform permanent
                  scans on the accounts and the activity in these accounts to
                  prevent virus and malware infections.
                </p>
                <p>
                  Identified infected files will be automatically placed in
                  quarantine to ensure the protection of the account and third
                  parties accessing the site in question. The probability of
                  affecting the operation of the infected sites is minimal,
                  however in certain situations, if important files are infected
                  (eg: index.html) this can lead to site malfunctions.
                </p>
                <p>
                  Customers have the obligation to immediately notify us of any
                  observed malfunctions, to check if this is due to malware
                  infections. Under these conditions, SiteBunker will provide
                  the list of quarantined files to the customer for their
                  cleaning.
                </p>
                <p>
                  The quarantined files are kept for 30 days on the server,
                  after which they are automatically deleted by the system.
                </p>
                <p>
                  Art. 17.5. Redirection of emails (Forward) to certain free
                  email service providers such as: yahoo, hotmail, gmail, etc.
                  it is FORBIDDEN and blocked on our servers. We reserve the
                  right to block or delete any redirection of this kind, due to
                  the policy of these providers to block the IP of the server
                  from which this kind of redirection is made.
                </p>
                <p>
                  Art. 17.6. Apache / PHP Limitations and Disabled Functions:
                </p>
                <p>disable_functions:</p>
                <p>
                  system, exec, passthru, proc_open, popen, shell_exec, symlink,
                  link, dl, syslog, pcntl_exec, pcntl_fork, pcntl_signal,
                  pcntl_waitpid, pcntl_wexitstatus, pcntl_wifexited,
                  pcntl_wifsignaled, pcntl_wifstopped, pcntl_wstopsig,
                  pcntl_wtermsig
                </p>
                <p>
                  Art. 17.7.All hosting packages are limited to 200/hour of
                  e-mail messages that can be sent from each account.
                </p>
                <p>
                  NOTE The headers of messages sent without authentication, the
                  mail() function, cannot be rewritten!
                </p>
                <p>
                  NOTE The DEDICATED IP is purchased separately for each hosting
                  package (cPanel account)!
                </p>
                <p>18. SPECIAL CONDITIONS DEDICATED SERVERS</p>
                <p>
                  Art. 18.1. Payments made for Dedicated Servers are not
                  refundable.
                </p>
                <p>
                  Art. 18.2. The servers are offered without management, the
                  customer is responsible for managing his own server.
                </p>
                <p>
                  Art. 18.3. The customer is responsible for performing a
                  back-up for the existing data on the server. SiteBunker does
                  not offer backup services for servers and no guarantee
                  regarding the integrity of the data on the server.
                </p>
                <p>
                  Art. 18.4.Payment for VPS Server services is made in advance.
                  Payment for VPS servers must be made no later than the service
                  expiration date (service due date). If the payment is not made
                  by this date, the server will be closed and permanently
                  deleted, including all data existing on the server, they will
                  not be able to be recovered after deletion.
                </p>
                <p>19. TECHNICAL SUPPORT CONDITIONS</p>
                <p>
                  The technical support offered is of the &quot;best
                  effort&quot; type. SiteBunker does not offer guarantees
                  regarding the response times and the resolution of requests
                  addressed to the support system.
                </p>
                <p>Technical support is provided for:</p>
                <ul>
                  <li>
                    <p>use of hosting services;</p>
                  </li>
                  <li>
                    <p>
                      use of email services (we do not provide support for email
                      software settings (eg: Outlook, Thunderbird, iPhone Mail,
                      Android Email, etc.)).
                    </p>
                  </li>
                </ul>
                <p>Technical support is NOT provided for:</p>
                <ul>
                  <li>
                    <p>creating and managing web pages;</p>
                  </li>
                  <li>
                    <p>the use of software for sending emails;</p>
                  </li>
                  <li>
                    <p>verification and repair of site operating errors;</p>
                  </li>
                  <li>
                    <p>
                      free software available to customers (eg: SiteBuilder,
                      Softaculous, etc.).
                    </p>
                  </li>
                </ul>
                <p>Support offered for a fee:&nbsp;</p>
                <p>
                  SiteBunker can provide support for a fee upon customer request
                  for specific technical situations exclusively for services
                  that are hosted on our servers.
                </p>
                <p>
                  In the case of SSL certificate installation services, these
                  are offered for a fee, are valid exclusively for accounts
                  hosted on our servers and refer to the installation of the
                  certificate on the server and do not include the necessary
                  settings at the site level. The changes that must be made in
                  the site are strictly the responsibility of the customer.
                </p>
                <p>20. BACK-UP</p>
                <p>
                  Art. 20.1. Customers have the obligation to periodically save
                  backup copies of the data stored in the hosting packages on
                  their own media. For this purpose, the cPanel software offers
                  tools for generating archives that contain data (files,
                  emails, databases) and hosting package settings automatically
                  or manually via FTP, PhpMyAdmin or FileManager depending on
                  your preferences. Customers must ensure that they have enough
                  space in the hosting package to generate backup copies, that
                  they are downloaded to their own storage medium and that the
                  saved data is intact and usable.
                </p>
                <p>
                  Art. 20.2. The back-up services (safety copies and data
                  protection) are implemented by SiteBunker on several storage
                  media and restore points that may differ depending on the
                  package or server. The physical protection of the hard disks
                  is ensured by RAID, the hosting accounts are saved overnight
                  (the running interval depends on the package type) on
                  dedicated storage media through cpbackup (cPanel), on external
                  storage servers through specialized software and an external
                  backup that can only be used in cases of force majeure.
                </p>
                <p>
                  Although we constantly invest in technology and resources, we
                  cannot offer any guarantee regarding the correct functioning
                  of the backup systems, the existence of the backup data or the
                  integrity of the data stored for backup purposes. Since in
                  certain cases and circumstances the data can be lost without
                  the possibility of being recovered, the customers have the
                  obligation to make safety copies / backup of the data on their
                  own storage media.
                </p>
                <p>
                  Art. 20.3. Backups that can be used for data recovery are
                  generated periodically, overnight, through the cpbackup system
                  (cPanel) and the dedicated back-up system and kept on
                  dedicated storage media in several versions (restore points).
                  Customers have access through Cpanel to the copies created in
                  this regard to restore or download their back-up files.
                </p>
                <p>
                  Upon request, we try to offer customers archives of the
                  hosting package stored on the backup harddisk, however we do
                  not offer any guarantee related to the existence of the data
                  or the integrity of the backup data. Customers have the
                  obligation to periodically save backup copies of the data
                  stored in the hosting packages on their own media, using the
                  tools available in the hosting packages.
                </p>
                <p>
                  Customers can request a manual restore from the SiteBunker
                  technical team within the limit of 3 restores per month.
                  Additional restores performed by the technical team will be
                  charged 20 USD per restore.
                </p>
                <p>
                  After deleting the hosting account from the server, customers
                  can request to search for a possible back-up on the disaster
                  recovery solution. This will be provided if it is identified
                  against a fee of 20 USD.
                </p>
                <p>
                  Art. 20.4. Backup exclusion limits for the number of files
                  (inodes) and writing to the account: unlimited.
                </p>
                <p>21. CUSTOMER RESPONSIBILITIES</p>
                <p>
                  Art. 21.1. The customer has the obligation to update the
                  contact information and at least a functional email address in
                  the control panel, the SiteBunker customer panel and the
                  billing data, whenever necessary. We cannot be held
                  responsible for confusion in communication as a direct result
                  of the customer&apos;s failure to perform the necessary
                  updates.
                </p>
                <p>
                  Art. 21.2. The customer is responsible for keeping the
                  username, passwords and other sensitive information safe, such
                  as: access to the hosting package, the customer panel, the
                  software used on the website, etc. If there is any doubt in
                  this regard, the customer must modify or request modification
                  of the authentication data by contacting the assistance team
                  as soon as possible.
                </p>
                <p>
                  Art. 21.3. The customer is fully responsible for securing the
                  content hosted on our servers, and if the content has been
                  compromised (infected with viruses, shells, phishing, etc.) he
                  is fully responsible for cleaning and securing his own
                  content. SiteBunker has NO responsibility for securing or
                  cleaning customer content.
                </p>
                <p>
                  Art. 21.4. The access history (Access Logs) is processed daily
                  by the server and deleted automatically. If you want to
                  archive the access history, the customer can save the access
                  history (access log) using the option available in the cPanel
                  control panel. The access history includes the IPs that
                  accessed the site through the http and/or ftp interface.
                </p>
                <p>
                  Art. 21.5. The customer has the obligation to keep the
                  commercial or free software/ scripts used on the hosting
                  accounts updated to the latest (newest) version. Also, the
                  customer has the obligation to update the scripts used within
                  24 hours of notifying SiteBunker. Otherwise, SiteBunker
                  reserves the right to delete or block access to the content of
                  the hosting account without prior notice.
                </p>
                <p>
                  Art. 21.6. The customer guarantees that all materials of any
                  kind held in the customer account are his property and that he
                  has the legal right to use or publish, and that any
                  responsibility regarding the content of the account rests
                  entirely with him, SiteBunker having no responsibility in this
                  regard.
                </p>
                <p>22. COMMUNICATION WITH SiteBunker CUSTOMERS</p>
                <p>
                  Art. 22.1. Communication with customers takes place via mail
                  or tickets.
                </p>
                <p>
                  Art. 22.2. The customer agrees to receive any information
                  regarding the services, the issuance of proforma, notification
                  of non-payment, suspension of services or other communications
                  related to SiteBunker&apos;s activity on any of the
                  communication channels above.
                </p>
                <p>
                  Art. 22.3. Communication with customers is limited only to the
                  services offered by SiteBunker and will not have advertising
                  purposes, unless the customer expresses his agreement in this
                  regard.
                </p>
                <p>
                  Art. 22.4. We will consider requests for data provision,
                  password reset, data changes or service cancellation
                  notifications only if they are sent from the existing email
                  addresses in the customer account, to ensure the verification
                  of the identity of the customer making the request.
                </p>
                <p>
                  Art. 22.5. In the event that the existing addresses in the
                  customer&apos;s account are no longer valid at the time of the
                  request for the provision of information by the customer, or
                  the request for information comes from a different email
                  address than the one in the customer&apos;s account,
                  SiteBunker will request from the customer a copy of the
                  Identity Card and/or the Certificate of Registration of the
                  company as the case may be, to verify the identity of the
                  applicant. We will also ask for a signed and/or stamped
                  address from the individual or company to change the email
                  addresses in the system.
                </p>
                <p>
                  Art. 22.6. The customer agrees and understands that in the
                  above situation, at the request of SiteBunker, he is obliged
                  to make the requested documents available to him, otherwise,
                  his request cannot be honored by SiteBunker.
                </p>
                <p>
                  Art. 22.7. The documents requested by SiteBunker will be
                  issued in a copy signed and/or stamped by the customer , as
                  the case may be, certifying the conformity of the document
                  with the original one, with the mention - in accordance with
                  the original - being made in this sense.
                </p>
                <p>
                  Art. 22.8. The customer assumes full responsibility for the
                  documents requested and provided to SiteBunker, the latter
                  only having the obligation to verify the correctness of the
                  information on the documents provided by the customer, not
                  their validity or legality, the customer assuming by providing
                  them the validity and legality them and their conformity with
                  the original.
                </p>
                <p>23. LIMITED LIABILITY</p>
                <p>
                  Art. 23.1. We cannot be held responsible for damages caused by
                  the temporary unavailability of our servers, for whatever
                  reason. This provision also includes damages resulting from
                  data damage or loss. The customer agrees to guarantee and hold
                  us harmless in relation to any claims and/or damages, without
                  being limited to these, caused to third parties as a result of
                  the use of the services.
                </p>
                <p>
                  Art. 23.2. SiteBunker offers technical support to all
                  customers to ensure the proper functioning of the services
                  offered.
                </p>
                <p>
                  In case of excessive requests for technical support for the
                  customer&apos;s personal purposes (restores, migrations,
                  etc.), SiteBunker reserves the right to impose a fee either
                  per hour or for the total intervention. Free migrations are
                  offered only to customers who have access to the cPanel
                  account of the former hosting provider. Without this access
                  and without the &quot;Backup Wizzard&quot; function activated
                  at the former hosting provider, a fee will be charged
                  depending on the complexity of the migration.
                </p>
                <p>
                  Art. 23.3. SiteBunker is not responsible for providing
                  customers with the access history of the sites (access log),
                  they can be archived and downloaded by the customer from the
                  control panel.
                </p>
                <p>
                  Art. 23.4. SiteBunker does not provide technical support
                  regarding the use of free scripts offered to customers.
                </p>
                <p>
                  SiteBunker reserves the right to stop offering the free
                  scripts at any time, without prior notice.
                </p>
                <p>
                  Free scripts offered include but are not limited to
                  RVSitebuilder, Softaculous Package, etc.
                </p>
                <p>
                  The support service is offered only for the use of hosting
                  services.
                </p>
                <p>
                  Art. 23.5. SiteBunker is not responsible and cannot be held
                  responsible for providing the customer&apos;s contact
                  identification data in the cases provided for in the
                  &quot;Registration of domains&quot; chapter of this contract,
                  the customer understanding that without the display of this
                  information the registration of international domains cannot
                  be carried out.
                </p>
                <p>
                  Art. 23.6. SiteBunker is not responsible and cannot be held
                  responsible, if the user&apos;s site has been blocked and
                  reported as spam, access has been blocked if infected files
                  (malware) have been identified that can harm third parties,
                  the site is exploited in order to be used for apparently
                  illegal activities, or access to the site is blocked as a
                  result of server failure or the existence of corrupt files or
                  defects that cause the site or service to be unusable. In all
                  these cases, SiteBunker responds within the limits, terms and
                  conditions provided in the chapter &quot;Guarantees and
                  refunds&quot; of this contract.
                </p>
                <p>
                  Art. 23.7. The customer understands and agrees that SiteBunker
                  is only responsible within the limits and conditions assumed
                  in the &quot;Guarantees and refund&quot; chapter of this
                  contract, any other liability of any nature, cannot be engaged
                  or claimed from SiteBunker, the liability and warranty offered
                  being limited to the chapter &quot;Guarantees and the return
                  of money&quot; in the contract.
                </p>
                <p>
                  Art. 23.8. You agree that if we are threatened with a lawsuit
                  by a third party, we can request written assurances from you,
                  assurances in which you promise us protection; if you do not
                  provide these assurances, this may be considered a breach of
                  the contract between the parties and may lead to the
                  suspension of the hosting service.
                </p>
                <p>
                  Art. 23.9. The customer understands and has the obligation to
                  inform himself, and to accept the registration rules, the
                  terms and conditions imposed by SiteBunker&apos;s partners,
                  regarding the registration of both Romanian and international
                  domains, SiteBunker being only the intermediary between the
                  customer and the partner, unable be held responsible for the
                  rules, terms and conditions imposed by the customer partner,
                  or for any restrictions, requests, or damage, loss of data,
                  customer details or seller details, revenue or other damages
                  to the business due to delays, wrong transmission or
                  non-transmission of information , due to restriction or
                  interruption of access, bugs or other errors, unauthorized use
                  due to sharing of access to the service, or other interactions
                  with the service contracted by customer from SiteBunkers or
                  through SiteBunkers from one of its partners.
                </p>
                <p>24. FINAL PROVISIONS</p>
                <p>
                  Art. 24.1. If, for any reason, you are not satisfied with the
                  services we offer you, please contact us and tell us what the
                  problem is in order to solve it. If we cannot solve your
                  problem and you want to terminate the contract, you can inform
                  us of this and you will receive back the money for the SHARED
                  web hosting services paid in advance. Payment for domain
                  registration, Dedicated Servers, Licenses of any kind, SSL
                  Certificates, ID Protect or other related services are not
                  refundable.
                </p>
                <p>
                  Art. 24.2. We will allocate all the necessary resources in
                  investigations into acts of violation of system or network
                  security, and we will cooperate with the authorities in case
                  of crimes. Customers who violate these provisions may be held
                  criminally or civilly liable.
                </p>
                <p>
                  Art. 24.3. For customers with multiple accounts or resellers,
                  we reserve the right to suspend all accounts if the owner of
                  the main account violates these provisions to an extent that
                  we consider to be very serious.
                </p>
                <p>
                  Art. 24.4. We are the only ones in a position to judge what
                  constitutes a violation of the above terms and conditions.
                  Failure to comply with the above terms and conditions is
                  grounds for account suspension or deactivation.
                </p>
                <p>
                  Art. 24.5. Any dispute arising in connection with the use of
                  this service will be resolved amicably. If the conflict has
                  not been resolved, any dispute arising from or in connection
                  with this contract, including regarding its conclusion,
                  execution or termination, will be settled by the arbitration
                  of the Galati Commercial Arbitration Court next to the Chamber
                  of Commerce and Industry of Galati, in accordance with the
                  Rules of Arbitration Procedures of this Court. The arbitration
                  award is final and binding.
                </p>
                <p>
                  Art. 24.6. We do not accept orders containing false personal
                  data, including Name, Surname, Address, City, County, Email
                  address. Any order of this type will be canceled without
                  notifying the customer beforehand.
                </p>
                <p>
                  Art. 24.7. We do not accept orders of &quot;TEST&quot; hosting
                  packages for free domains, hosting packages that have set
                  dedicated IP, TLDs such as .TK, .GA, .ML, .CF, .GQ, etc. and
                  neither for unregistered domains, news domains, nor for
                  domains related to online games, nor for domains that have
                  pornographic words in their content. Any order of this type
                  will be canceled without notifying the customer beforehand. We
                  do not accept orders for &quot;TEST&quot; type hosting
                  packages that include the dedicated IP.
                </p>
                <p>
                  Art. 24.8. Migrations from the old web hosting provider will
                  be carried out between Monday-Friday: 9-17 (UTC/GMT +2), with
                  the exception of legal holidays. Also, the migrations will be
                  carried out if and only if the old hosting provider will
                  provide access to a cPanel account with the full backup
                  function activated.
                </p>
                <p>25. PRIVACY POLICY</p>
                <p>
                  The PRIVACY POLICY can be consulted at{" "}
                  <a href="https://sitebunker.net/privacy-policy/">
                    https://sitebunker.net/privacy-policy/
                  </a>{" "}
                  and is an integral part of the Terms of Service.
                </p>
                <p>
                  Art. 26.Cryptocurrency mining is prohibited both on physical
                  servers and on virtual servers (VPS). It is forbidden to
                  launch Phishing, Flood, DDoS, DoS attacks from physical
                  servers as well as virtual servers (VPS). In the event that
                  Exim Host SRL reports some of the actions mentioned above, the
                  services from which these actions are launched will be
                  suspended.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  )
}
export default Gdpr
